//settings
$fa-font-path: "../font";

// Colors //
$black: #151515;
$white: #fff;
$primary-color: #777;
$gray: #d7d7d5;

//Fonts
$primary-font: "adobe-garamond-pro", sans-serif;
$secondary-font: 'BelledeMai4.0', serif;
