@import "variables";
// includes
@import "includes/functions";
@import "includes/normalize";
@import "includes/45p";
@import "includes/fonts";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $black;
    color: $white;
    font-family: $primary-font;
    font-weight: 300;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    transform-origin: center;
    display: inline-block;
    @include hover{
        transform: scale(1.1);
    }
}
#header{
    position: fixed;
    background-color: $black;
    color: $white;
    height: rem(50);
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    .container{
        min-height: rem(50);
        padding: 0 rem(10);
        display: flex;
        justify-content: center;
        align-items: center;
        
        @media screen and (max-width: 799px){
            justify-content: space-between;
        }
        li{
            @media screen and (min-width: 800px){
                flex: 1;
                text-align: center;
            }
        }
        a{
            text-transform: lowercase;
        }
        h1{
            font-family: $secondary-font;
            font-size: rem(30);
            text-transform: uppercase;
            font-weight: normal;
            opacity: 1;
            transition: .3s opacity;
            @media screen and (min-width: 800px){
                &.hide{
                    opacity: 0;
                }
            }
        }
        @media screen and (min-width: 800px){
            .mobile{
                display: none;
            }
        }
        @media screen and (max-width: 799px){
            .desktop{
                display: none;
            }
        }

        .menu-toggle{
            span:last-child{
                display: none;
            }
            &.open{
                span:first-child{
                    display: none;
                }
                span:last-child{
                    display: inline;
                }   
            }
        }
    }
    .nav{
        
        @media screen and (min-width: 800px){
            display: none;
        }
        padding: rem(50) 0;
        text-align: center;
        background-color: $black;
        transition: right .5s;
        .menu li{
            padding: 1em 0;
        }
        .social{
            margin-top: rem(60);
            li{
                display: inline-block;
                &+li{
                    margin-left: .8em;
                }
            }
        }
        position: absolute;
        right: -150vw;
        top: 100%;
        width: 100%;
        &.open{
            right: 0;
        }
    }
}
.page-body{
    position: relative;
    z-index: 99;
    padding-top: rem(50);
}
#main{
    >section{
        width: 100%;
        max-width: 2000px;
        margin: 0 auto;
    }
}

#hero{
    position: relative;
    img{
        display: block;
        width: 100%;
        height: auto;
    }
    h1{
        
        font-family: $secondary-font;
        font-size: rem(120);
        text-transform: uppercase;
        font-weight: normal;

        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @media screen and (max-width: 799px){
            display: none;
        }
    }
}

#music{
    display: flex;
    p{
        margin: 0;
        font-size: rem(16);
        text-transform: lowercase;
    }
    h2{
        margin: rem(10) 0;
        font-family: $secondary-font;
        font-size: rem(36);
        letter-spacing: .4em;
        @supports (-webkit-touch-callout: none) {
            text-indent: .8em; 
        }
        text-transform: uppercase;
        text-align: center;
        @media screen and (min-width: 1151px){
            br{
                display: none;
            }
        }
    }
    .btns{
        a{
            display: flex;
            width: max-content;
            margin: 1em auto;
        }
    }
    a{
        text-transform: uppercase;
        font-size: rem(13);
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        letter-spacing: .2em;
        img{
            margin-left: .5em;
            width: rem(15);
            filter: brightness(0) invert(1);
        }
    }

    .col{
        flex: 1;
        text-align: center;
        padding: rem(100) rem(10);
    }

    .music-head{
        font-weight:600;
        margin: rem(100) 0 rem(10);
    }
    .more-music{
        width: 100%;
        max-width: rem(300);
        margin: 0 auto 0;
        padding: 0 rem(40);

        .owl-prev, .owl-next{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            img{
                width: rem(24);
            }
        }

        .owl-prev{
            left: 0;
            transform: translateY(-50%) rotate(180deg);
        }
        .owl-next{
            right: 0;
        }
        a{
            margin-top: 1em;
        }
    }

    .bg{
        background-image: url('../img/BOMM.jpg');
        background-size: cover;
        background-position: center;
    }

    
    @media screen and (max-width: 899px){
        .col.bg{
            display: none;
        }
    }
}

#tracklist{
    position: relative;
    padding: rem(100) 0;
    @media screen and (max-width: 899px){
        padding: rem(50) 0;
    }
    p{
        text-transform: lowercase;
        font-size: rem(16);
        text-align: center;
        margin-bottom: rem(20);
    }
    video{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .container{
        position: relative;
        z-index: 4;
        max-width: rem(1080);
        margin: 0 auto;
        
        @media screen and (max-width: 899px){
            max-width: none;
        }
    }
    .tracks{
        font-weight: bold; 
        display: flex;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        width:100%;
        margin-left: rem(-40);
        
        font-family: $secondary-font;
        font-size: rem(20);
        text-transform: uppercase;
        letter-spacing: .02em;
        line-height: 3;
        
        @media screen and (max-width: 899px){
            
            margin-left: rem(-28);
            font-size: rem(15);
            line-height: 1.6;
        }


        li{
            display: flex;
            align-items: center;
            span{
                display: inline-block;
            };
            &::before{
                position: relative;
                content: '';
                height: 1px;
                width: rem(40);
                margin: 0 rem(30) 0 rem(26);
                display: inline-block;
                background-color: $white;
                
                @media screen and (max-width: 899px){
                    width: rem(20);
                    margin: 0 rem(10) 0 rem(8);
                }
            }
            &.row-start{
                &::before{
                    background-color: transparent;
                }
            }
        }
    }
}

#video{
    padding: rem(30) 0;
    color: $black;
    background-color: $gray;
    background-image: url('../img/video-bg.png');
    background-size: cover;
    .container{
        max-width: rem(1300);
        @media screen and (max-width: 899px){
            padding: 0;
        }
    }
    .color-link{
        flex-basis: 23%;
        text-transform: uppercase;
        font-size: rem(13);
        .video{
            overflow: hidden;
            filter:grayscale(1);
            transition: all .3s;
        }
        @include hover{
            .video{
                filter:grayscale(0);
            }
            transform: none;
        }
        video{
            display: block;
            width: 100%;
            height: auto;
        }

        .title{
            font-family: $secondary-font;
            font-size: rem(16);
            margin-bottom: rem(10);
            font-weight: bold;
            display: block;
        }
        .link{
            font-size: rem(11);
            margin-top: 1em;
            display: flex;
            align-items: center;
            justify-content: space-between;
            letter-spacing: .2em;
            img{
                margin-left: .5em;
                width: rem(14);
                filter: brightness(0);
            }
        }
    }
}

#tour{
    background-image: url('../img/tour-bg.png');
    
    background-size: 100% auto;
    background-position: center top;
    padding: rem(50) rem(25);
    min-height: 40vw;
    @media screen and (max-width: 860px){
        background-image: url('../img/tour-bg-m.png');
        padding: rem(100) rem(25);
        min-height: 90vw;
    }
    .container{
        width: 100%;
        max-width: rem(320);
        background-image: url('../img/tour-bg-in.png');
        background-size: 100% auto;
        text-align: center;
        padding: rem(50) rem(10); 
        color: $black;

        h2{
            font-family: $secondary-font;
            text-transform: uppercase;
            font-size: rem(18);
            margin-bottom: rem(20);
            font-weight: normal;
            display: block;
        }

        .event{
            margin-bottom: rem(30);
            font-size: rem(13);
            line-height: 1.5;

            .event-date{
                font-weight: bold;
                font-size: rem(15);
            }
            .event-venue{
                letter-spacing: .2em;
                margin-bottom: 0;
            }
            .event-location{
                font-style: italic;
                font-size: rem(15);
            }
            a{
                font-size: rem(13);
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: .2em;
                img{
                    margin-left: .5em;
                    width: rem(14);
                    filter: brightness(0);
                }
            }

            &:nth-child(n+3){
                display: none;
            }
        }

        #events.seeall .event{
            &:nth-child(n+3){
                display: block;
            }
        }
    }
}

#footer{
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 899px){
        flex-direction: column;
    }
    >div{
        flex:1;
        &.head{
            flex-basis: 100%;
            text-align: center;
            border-bottom: 2px solid $white;
        }
    }
    .social{
        text-align: center;
        padding: rem(10);
        border-right: 2px solid $white;
        display: flex;
        justify-content: center;
        align-items: center;
        
        @media screen and (max-width: 899px){
            border-right: none;
            border-bottom: 2px solid $white;
        }
        a{
            @include hover{
                text-decoration: underline;
            }
        }
    }
    .social-links{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: rem(11);
        text-transform: uppercase;
        li{
            flex-basis: 31%;
            line-height: 1.5;
        }
    }

    .newsletter{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: rem(20);
        form{
            border-bottom: 2px solid $white;
        }
        input{
            color: $white;
            font-size: rem(11);
            text-transform: uppercase;
            &::placeholder{
                color: $white;
            }
        }
        button{
            color: $white;
            margin-left: rem(-5);
            img{
                width: rem(16);
            }
            filter: brightness(0) invert(1);
        }
    }

    .copyright{
        padding: rem(10);
        border-left: 2px solid $white;
        font-size: rem(10);
        text-align: right;
        svg{
            display: block;
            margin-left: auto;
            margin-bottom: rem(8);
        }
        @media screen and (max-width: 899px){
            border-left: none;
            border-top: 2px solid $white;
            text-align: center;
            svg{
                margin: 0 auto rem(8);
            }
        }
    }
}