@font-face {
    font-family: 'BelledeMai4.0';
    src: url('../font/BelledeMai40-Regular.woff2') format('woff2'),
        url('../font/BelledeMai40-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BelledeMai4.0';
    src: url('../font/BelledeMai40-Thin.woff2') format('woff2'),
        url('../font/BelledeMai40-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'BelledeMai4.0';
    src: url('../font/BelledeMai40-Heavy.woff2') format('woff2'),
        url('../font/BelledeMai40-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'BelledeMai4.0';
    src: url('../font/BelledeMai-Bold.woff2') format('woff2'),
        url('../font/BelledeMai-Bold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

